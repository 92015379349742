import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import ru from '@/locale/ru';
import en from '@/locale/en';

export default new Vuetify({
    lang: {
        locales: { ru, en },
        current: 'ru',
    },
    theme: { dark: true },
});
