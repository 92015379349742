<template>
    <v-row justify="center" align="center">
      <v-col class="fixedwidthhomecol">

        <v-row justify="center" align="center">
          <!-- ЛЕВАЯ КОЛОНКА -->
          <v-col  cols="10" md="5" style="max-width: 630px;">
            <h1 class="text-center">{{ $vuetify.lang.t('$vuetify.SQHome_Title') }}</h1><br />
            <p class="text-center text--lighten-1">{{ $vuetify.lang.t('$vuetify.SQHome_Description') }}</p><br />
          </v-col>

          <v-col cols="1" class="d-none d-sm-none d-md-flex d-lg-flex"></v-col>
          <!-- ПРАВАЯ КОЛОНКА -->
          <v-col  cols="10" md="5" class="flex-shrink-0 pa-0">
            <!-- ФОТКИ -->
            <div id="galaryLaptop">
              <div id="galaryLaptopContainer">
                <v-card class="elevation-6" style="background-color: transparent !important;">
                  <v-carousel hide-delimiters height="auto" cycle hide-delimiter-background show-arrows-on-hover>
                    <v-carousel-item v-for="(image, index) in images" :key="index">
                      <v-sheet>
                        <v-row class="px-3" align="center" justify="center">
                          <img width="970" height="600" src="/img/imgLazy.gif" alt="" style="visibility:hidden;height:62%; background: url('/img/imgLazy.gif') repeat  " />
                          <img :src="image" alt="" style="width:100%; position:absolute;  " />
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>


        <!-- ВИДЕО -->
<!--        <v-row justify="center" id="samplesSection">-->
<!--          <v-col class="fixedwidthhomecol"><h2 class=" ml-3 ml-lg-8 text-lg-left text-center">{{ $vuetify.lang.t('$vuetify.SQApp_Examples_Title') }}</h2></v-col>-->
<!--        </v-row>-->
<!--        <br/>-->
<!--        <v-row justify="center">-->
<!--          <v-col class="fixedwidthhomecol">-->
<!--            <v-carousel hide-delimiters height="350" show-arrows>-->
<!--              <v-carousel-item v-for="(threeVideos, index) in vidoes()" :key="index">-->
<!--                <v-row class="px-3" align="center" justify="center">-->
<!--                  <v-col v-for="video, index2 in threeVideos" :key="index2" cols="auto">-->
<!--                    <video @play="onVideoPlay" class="homecontentvideo" :poster="video.file + '.png'" preload="none" height="auto" loop controls>-->
<!--                      <source :src="video.file + '.mp4'" type='video/mp4' />-->
<!--                      Your browser doesn't support HTML5 video tag.-->
<!--                    </video>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-carousel-item>-->
<!--            </v-carousel>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <br/>

            <v-row justify="center" align="center">
              <!--left-->
              <v-col  cols="10" md="5" align="center" justify="center">
<!--                <video @play="onVideoPlay" class="homecontentvideo" style="max-width: 2000px;max-height: 2000px;" :poster="videos[0].file + '.png'" preload="none" height="auto" loop controls>-->
<!--                  <source :src="videos[0].file + '.mp4'" type='video/mp4' />-->
<!--                  Your browser doesn't support HTML5 video tag.-->
<!--                </video>-->
                  <v-responsive :aspect-ratio="16/9">
                      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?list=PLFEaeUO1zLXOM0jua09x-N4KE0YqG7SQ5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </v-responsive>
                <br/>
                <br/>
              </v-col>

              <v-col cols="1" class="d-none d-sm-none d-md-flex d-lg-flex"></v-col>
              <!--right-->
              <v-col  cols="10" md="5" class="flex-shrink-0 pa-0">
                <v-row id="downloadButtonsDiv" justify="center">
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkCombine800480()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionCombineG1') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLink()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionCombine') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkTractor1000()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionTractor') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkTractor2001()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionTractor2001') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkTractor20003000()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionTractor20003000') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkSeeder()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionSeeder') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkCropper()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionCropper') }}</v-btn>
                  <v-btn :class="{'v-btn--block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkForage()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionForage') }}</v-btn>
<!--                  <v-btn :class="{'v-btn&#45;&#45;block': $vuetify.breakpoint.xs}" outlined :href="GetSetupLinkKirovets()"><v-icon left large class="mr-4 ml-2 mt-1">mdi-microsoft-windows</v-icon>{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionKirovets') }}</v-btn>-->
                </v-row>
              </v-col>
            </v-row>

        <br/>
      </v-col>
    </v-row>
</template>

<script>

export default {
  name: 'Home',
  data(){
    return {
      images: [
        "/img/slide1.png?v=1",
        "/img/slide2.png?v=1",
        "/img/slide3.png?v=1",
        "/img/slide4.png?v=1",
        "/img/slide6.png?v=1",
        "/img/slide7.png?v=1",
        "/img/slide8.png?v=1",
        "/img/slide9.png?v=1",
        "/img/slide10.png?v=1",
        "/img/slide11.png?v=1",
        "/img/slide12.png?v=1",
        "/img/slide13.png?v=1",
        "/img/slide14.png?v=1",
        "/img/slide15.png?v=1",
        "/img/slide16.png?v=1",
        "/img/slide17.png?v=1",
        "/img/slide18.png?v=1",
        "/img/slide19.png?v=1",
        "/img/slide20.png?v=1",
      ],
      videos: [
        {file: this.SiteVideosFolder() + "/9", caption: "SQApp_Examples_Title"},
      ],
    }
  },

  components: {
  },
  methods: {
    vidoes() {
      let result = [];
      if (this.videos) {
        let num = 3;
        if (this.$vuetify.breakpoint.smAndDown)
          num = 1;
        else if (this.$vuetify.breakpoint.mdAndDown)//lg
          num = 2;
        for (let i = 0; i < this.videos.length; i += num) {
          let subarray = [];
          for (let j = 0; j < num; j++) {
            if (this.videos.length > (i + j))
              subarray.push(this.videos[i + j]);
          }
          result.push(subarray);
        }
      }
      return result;
    },
    onVideoPlay(event) {
      let videos = document.getElementsByClassName('homecontentvideo');
      for (let video of videos) {
        if (video !== event.target) {
          video.pause();
        }
      }
    },
  }
}
</script>
<style  lang="scss">
    $agBnColor: rgb(25, 69, 107);
  #downloadButtonsDiv .v-btn {
    border-width: 2px;
    margin-bottom: 10px;
    border-color: $agBnColor !important;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 29px;
    padding-right: 29px;
    height: 60px;
    background-color: transparent !important;
  }

  #galaryLaptop {
    border-style: solid;

    border-image-source: url(/img/frame.png);
    border-image-slice: 172;
    border-image-width: 60px;

  }

  #galaryLaptopContainer {
    position: relative;

    padding: 40px 59px;
  }

  #galaryLaptop .v-card {
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none !important;
  }

  #galaryLaptop .v-sheet{
    background-color: transparent !important;
  }

  #galaryLaptop .v-sheet {
    background-color: transparent;
  }
  .homecontentvideo {
    max-width: 450px;
    max-height: 304px;
    width: 100%;
  }

</style>