import {ru} from 'vuetify/lib/locale';
export default {
    ...ru,

    noDataText: 'Пусто',
    carousel: {prev: 'Предыдущее', next: 'Следующее'},

    Error: 'Ошибка',
    Done: 'Готово',
    Ok: 'Ок',
    UserNameOrEmail: 'Имя пользователя или Email',
    UserName: 'Имя пользователя',
    Email: 'Email',
    Password: 'Пароль',
    ReturnToMain: 'Вернуться на главную',
    Download: 'Скачать',
    PasswordConfirm: 'Подтвердите пароль',

    ErrShort: 'Коротко',
    ErrPleaseLogin: 'Войдите в аккаунт',
    ErrNotEquals: 'Не совпадают',

    SQApp_Logo: 'Speqtroom',
    SQApp_Support: 'Поддержка',
    SQApp_Examples_Title: 'Примеры',
    SQApp_Documentation: 'Документация',
    SQApp_WebVersion: 'Web-версия',
    SQApp_WebVersionDesc: 'просмотр ПО',
    SQApp_WinVersionCombine: 'Комбайн Gen. 2',
    SQApp_WinVersionCombineG1: 'Комбайн Gen. 1',
    SQApp_WinVersionTractor: 'Трактор 1000',
    SQApp_WinVersionTractor20003000: 'Трактор 2000-3000',
    SQApp_WinVersionTractor2001: 'Трактор 2001',
    SQApp_WinVersionCropper: 'КСУ',
    SQApp_WinVersionForage: 'Кормоуборочный комбайн',
    SQApp_WinVersionSeeder: 'Посевной комплекс',
    SQApp_WinVersionKirovets: 'Кировец',
    SQApp_MacosVersion: 'MacOS-версия',
    SQApp_LinuxVersion: 'Linux-версия',

    Documentation_Intro: 'Общая информация',
    Documentation_Setup: 'Инструкция по установке',
    Documentation_LifeCycle: 'Процессы жизненного цикла ПО',

    SQHome_Title: 'Программное обеспечение для эмуляции процессов работы комбайнов, тракторов, сельхозтехники.',
    SQHome_Description: `Эмулируются режимы движения техники, режимы работы силовых агрегатов техники и режимы работы агрегатов назначения техники.
Алгоритмы эмуляции подразумевают перемещение или вращения валов, движения, поворотов и изменения прочих виртуальных параметров в ответ на управляющие воздействия каналов ПО`,
}