import {en} from 'vuetify/lib/locale';
export default {
    ...en,

    noDataText: 'Nothing',
    carousel: {prev: 'Prev', next: 'Next'},

    Error: 'Error',
    Done: 'Done',
    Ok: 'Ok',
    UserNameOrEmail: 'Username or Email',
    UserName: 'Username',
    Email: 'Email',
    Password: 'Password',
    ReturnToMain: 'Go to homepage',
    Download: 'Download',
    PasswordConfirm: 'Confirm password',

    ErrShort: 'Too short',
    ErrPleaseLogin: 'Please login',
    ErrNotEquals: 'Not equals',

    SQApp_Logo: 'Speqtroom',
    SQApp_Support: 'Support',
    SQApp_Examples_Title: 'Examples',
    SQApp_Documentation: 'Documentation',
    SQApp_WinVersion: 'Win-version',
    SQApp_WinVersionCombine: 'Combine Gen. 2',
    SQApp_WinVersionCombineG1: 'Combine Gen. 1',
    SQApp_WinVersionTractor: 'Tractor 1000',
    SQApp_WinVersionTractor20003000: 'Tractor 2000-3000',
    SQApp_WinVersionTractor2001: 'Tractor 2001',
    SQApp_WinVersionCropper: 'Cropper',
    SQApp_WinVersionForage: 'Forage harvester',
    SQApp_WinVersionSeeder: 'Seeder',
    SQApp_WinVersionKirovets: 'Kirovets',
    SQApp_MacosVersion: 'MacOS-version',
    SQApp_LinuxVersion: 'Linux-version',
    SQApp_WebVersion: 'Web-version',

    Documentation_Intro: 'General information',
    Documentation_Setup: 'Installation instructions',
    Documentation_LifeCycle: 'Software life cycle processes',

    SQHome_Title: 'Software for emulating the work processes of combines, tractors, agricultural machinery.',
    SQHome_Description: `Modes of movement of equipment, modes of operation of power units of equipment and modes of operation of units of destination of equipment are emulated.
Emulation algorithms imply the movement or rotation of shafts, movements, turns and changes in other virtual parameters in response to the control actions of the software channels`,
}