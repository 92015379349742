import Vue from 'vue';

Vue.mixin({
    methods: {
        SiteVideosFolder(){
            return "/sitevideos";
        },
        /**
         * @return "t=123123131" {string}
         */
        AntiCacheSuffix() {
            return 't=' + new Date().getTime();
        },
        SupportEmail() {
            let win = window.open("mai" + "lto:" + "support@autogramma.ru", '_blank');
            win.focus();
        },
        GetNavigatorLanguage() {
            if (navigator.languages && navigator.languages.length) {
                return navigator.languages[0];
            } else {
                return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US';
            }
        },
        GetCookie(key, defaultValue = '') {
            const match = document.cookie.match(new RegExp('(^| )' + key + '=([^;]+)'));
            if (match) return decodeURIComponent(match[2]);
            return defaultValue;
        },
        SetCookie(key, value, expires = undefined, httponly = false) {
            let cookieString = `${key}=${encodeURIComponent(value)}`;
            if (!this.IsLocalhost())
                cookieString += "; Secure";
            if (httponly)
                cookieString += '; HttpOnly';
            if (expires instanceof Date) //Либо указываем точную дату истекания cookie,
                cookieString += `; expires=${expires.toUTCString()}`;
            else if (!isNaN(expires)) //либо число секунд, через которое оно истекает
                cookieString += `; max-age=${expires}`;
            else
                cookieString += `; max-age=31536000`;
            document.cookie = cookieString;
        },
        /**
         * @return {string}
         */
        GetSetupLink(){
            return '/Setup/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkCombine800480(){
            return '/SetupCombine800480/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkTractor2001(){
            return '/SetupTractor2001/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkTractor1000(){
            return '/SetupTractor/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkTractor20003000(){
            return '/SetupTractor20003000/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkSeeder(){
            return '/SetupSeeder/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkKirovets(){
            return '/SetupKirovets/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkCropper(){
            return '/SetupCropper/setup.exe?' + this.AntiCacheSuffix();
        },
        GetSetupLinkForage(){
            return '/SetupForage/setup.exe?' + this.AntiCacheSuffix();
        },
        IsLocalhost() {
            return (location.hostname === "localhost" || location.hostname === "127.0.0.1");
        },

        ResetCookie(key) {
            this.SetCookie(key, "", 0);
        },
    }
});