<template>
  <v-app >
    <v-container style="height:100%;">
      <v-row justify="center">
        <v-col>
          <!-- TOP MENU -->
          <v-row align="center" justify="center">
            <v-col class="fixedwidthhomecol">
              <v-row justify="center">
                <v-col>
                  <router-link to="/" class="logo"><h1 class="mr-6 text-lg-left ml-3 ml-lg-8 text-center">{{ $vuetify.lang.t('$vuetify.SQApp_Logo') }}</h1></router-link>
                </v-col>
                <v-col cols="auto">
                  <v-row justify="end" :class="{'justify-center': $vuetify.breakpoint.xs}">
                    <div>
                      <v-btn to="/docs"   class="custom-transform-class text-none">
                        <img src="/icons/icnDocumentation.png" alt="" />&nbsp;
                        {{ $vuetify.lang.t('$vuetify.SQApp_Documentation') }}
                      </v-btn>
                    </div>
                    <v-btn @click="SupportEmail" class="custom-transform-class text-none">
                      <img src="@/assets/icnSupport.png" alt="" />&nbsp;
                      {{ $vuetify.lang.t('$vuetify.SQApp_Support') }}
                    </v-btn>
                    <v-btn @click="ChangeLocale">
                      <img src="@/assets/icnLanguage.png" alt="" />&nbsp;
                      <span class="uppercase">{{ $vuetify.lang.current }}</span>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col>
              <router-view></router-view>
            </v-col>
          </v-row>

          <v-row class="footer" justify="center">
            <v-col class="fixedwidthhomecol">
              <v-divider></v-divider>
              <v-row align="center" justify="center">
                    <v-col cols="auto">
                      <v-btn text :href="GetSetupLinkCombine800480()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionCombineG1') }}</v-btn>
                      <v-btn text :href="GetSetupLink()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionCombine') }}</v-btn>
                      <v-btn text :href="GetSetupLinkTractor1000()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionTractor') }}</v-btn>
                      <v-btn text :href="GetSetupLinkTractor2001()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionTractor2001') }}</v-btn>
                      <v-btn text :href="GetSetupLinkTractor20003000()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionTractor20003000') }}</v-btn>
                      <v-btn text :href="GetSetupLinkSeeder()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionSeeder') }}</v-btn>
                      <v-btn text :href="GetSetupLinkCropper()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionCropper') }}</v-btn>
                      <v-btn text :href="GetSetupLinkForage()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionForage') }}</v-btn>
<!--                      <v-btn text :href="GetSetupLinkKirovets()">{{ $vuetify.lang.t('$vuetify.SQApp_WinVersionKirovets') }}</v-btn>-->
                      <v-btn text  @click="SupportEmail">{{ $vuetify.lang.t('$vuetify.SQApp_Support') }}</v-btn><br/>
                    </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row justify="start">
                <v-col cols="auto" class="pa-10">Autogramma © 2014 - {{new Date().getFullYear()}}</v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
    <div id="homeBg">
      <svg xmlns='http://www.w3.org/2000/svg' height="100%" width="100%" viewBox="0 0 64 64" preserveAspectRatio="none">
        <!--            <path d='M0 55 Q55 60 60 0 L64 0 L0 0 Z'  fill="#0A0A0E"/>-->
        <path d='M0 58 Q50 60 64 40 L64 0 L0 0 Z' fill="rgb(38, 105, 163)" /> <!--середина-->
        <path d='M0 58 Q55 60 60 0 L64 0 L0 0 Z' fill="rgb(32, 90, 142)" /> <!--верх-->
      </svg>
    </div>

  </v-app>
</template>

<script>

  export default {
    name: 'App',
    components: {
    },
    mounted() {
      let lang = this.GetCookie('lang', '');
      if (!lang) {
        lang = this.GetNavigatorLanguage().includes('ru') ? 'ru' : 'en';
      }
      this.$vuetify.lang.current = lang;
    },
    methods: {
      ChangeLocale() {
        const currentLang = this.$vuetify.lang.current;
        this.$vuetify.lang.current = currentLang === 'ru'
                ? 'en'
                : 'ru';
        this.SetCookie('lang', this.$vuetify.lang.current);
      },
    }
  }
</script>

<style lang="scss">
    //$agBgColor: rgb(33, 92, 146);
    $agBgColor: rgb(30, 83, 130); //низ

    $agLight-5: #FAFAFA;
    $agDark-4: #212121;
    $agDark-3: #424242;
    $agDark-35: #353535;
    $agLight-3: #EEEEEE;
    $agDark-2: #616161;
    $agLight-2: #E0E0E0;
    $agDarkTitleBg: #424242;
    $agMainColor: rgb(25, 69, 107);
    $agMainColorDarkest: rgb(20, 64, 102);
    $agMainColorDarkestDarkest: rgb(15, 60, 100);
    $agBnColor: rgb(25, 69, 107);
    $agLightBnColor: #9fa8da;


html{
  overflow: auto !important;
}
body{
  overflow: initial !important;
}
.row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.v-application {
  color: white !important;
  background-color: transparent !important;
  font-family: "SF Text", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu !important;
}
    .v-application {
      display: block !important;
    }

.v-btn {
  text-transform: none !important;
  color: white !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: $agBnColor !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.fixedwidthhomecol {
  max-width: 1500px !important;
}

.notfixedwidthhomecol {
  max-width: 100% !important;
}

.logo {
  color: white !important;
  text-decoration: none !important;
}

#homeBg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /*background-color: #171A1C;*/
  background-color: $agBgColor;
}

.theme--dark.v-list {
  background: $agMainColor !important;
}

.theme--dark.v-card {
  background-color: $agMainColor !important;
  color: #fff;
}

.theme--dark.v-tabs-items {
  background-color: $agMainColor !important;
}

.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: $agMainColor !important;
}

.theme--dark.v-navigation-drawer {
  background-color: $agMainColor !important;
}

.theme--dark.v-sheet {
  background-color: $agMainColor !important;
}

.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: $agBnColor !important;
  border-color: $agBnColor !important;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: $agMainColorDarkestDarkest !important;
}

.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: transparent;
}

</style>
